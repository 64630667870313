import { Link } from "gatsby"
import React from "react"

// const Header = ({ siteTitle }) => (
const Header = () => (
  <header>
    <nav>
      <Link to="/" children={`moondog`} />
    </nav>
  </header>
)

export default Header
