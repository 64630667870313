import React from "react"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { graphql } from "gatsby"
import Layout from "../components/layout"

export default ({
  data: {
    datoCmsPage: { seoMetaTags, header, subHeader },
  },
}) => (
  <Layout>
    <HelmetDatoCms seo={seoMetaTags} />
    <article>
      <header>
        <h1>{header}</h1>
        <p>{subHeader}</p>
      </header>
    </article>
  </Layout>
)

export const query = graphql`
  query PageQuery($slug: String!) {
    datoCmsPage(slug: { eq: $slug }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      header
      subHeader
    }
  }
`
